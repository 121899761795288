import React, {Component} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import SalesOrderProductBuilderNavigationProduct from "./SalesOrderProductBuilderNavigationProduct";

export default class SalesOrderProductBuilderNavigationSubTab extends Component {

    render() {
        let { order, user, discountByProductCode, packagingAndHandling, products, navigation, handleOrderChange, toggleProductTab, productMarkupByProductCode, hasBulkOrderData } = this.props;

        return (
            <Card>
                <CardHeader>
                    <Nav tabs card>
                        {(navigation.subTab.list || []).map((p, pgIndex) => {
                                return <NavItem
                                    className={"hoverableItem"}
                                    key={pgIndex}>
                                    <NavLink
                                        className={classnames({active: navigation.subTab.active.code === p.code})}
                                        onClick={() => toggleProductTab(p.code)}>
                                        <Button color={"link"}
                                                className={"mr-2"}>
                                            {p.displayName}
                                            {
                                                (p.itemCount > 0)
                                                    ? <Badge color="primary"
                                                             className={"ml-2"}>{p.itemCount}</Badge>
                                                    : null
                                            }
                                        </Button>
                                    </NavLink>
                                </NavItem>;
                            }
                        )}
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent activeTab={navigation.subTab.active.code}>
                        {navigation.subTab.all.map((p, pIndex) => {
                                return <TabPane tabId={p.code} key={pIndex}>
                                    <SalesOrderProductBuilderNavigationProduct
                                        user={user}
                                        order={order}
                                        product={p}
                                        products={products}
                                        discountByProductCode={discountByProductCode}
                                        packagingAndHandling={packagingAndHandling}
                                        handleOrderChange={handleOrderChange}
                                        toggleProductTab={toggleProductTab}
                                        productMarkupByProductCode={productMarkupByProductCode}
                                        hasBulkOrderData={hasBulkOrderData}
                                    />
                                </TabPane>
                            }
                        )}
                    </TabContent>
                </CardBody>
            </Card>
        )
    }
}