import React, {Component} from 'react';
import {Badge, Button, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {cloneDeep, isEmpty} from 'lodash';
import classnames from 'classnames';
import SalesOrderProductBuilderNavigationProduct from "./SalesOrderProductBuilderNavigationProduct";
import SalesOrderProductBuilderNavigationSubTab from "./SalesOrderProductBuilderNavigationSubTab";
import OrderForm from "../../../../../components/sales/create/productBuilderKeywayVersion/OrderForm";

export default class SalesOrderProductBuilderNavigationTab extends Component {

    render() {
        let { order, user, discountByProductCode, packagingAndHandling, products, navigation, handleOrderChange, toggleProductTab, productMarkupByProductCode, hasBulkOrderData } = this.props;

        return (
            <div>
                <CardHeader>
                    <Nav tabs card>
                        {navigation.tab.list.map((p, pgIndex) => {
                                return <NavItem
                                    className={"hoverableItem"}
                                    key={pgIndex}>
                                    <NavLink
                                        className={classnames({active: navigation.tab.active.code === p.code})}
                                        onClick={() => toggleProductTab(p.code)}>
                                        <Button color={"link"}
                                                className={"mr-2"}>
                                            {p.displayName}
                                            {
                                                (p.itemCount > 0)
                                                    ? <Badge color="primary"
                                                             className={"ml-2"}>{p.itemCount}</Badge>
                                                    : null
                                            }
                                        </Button>
                                    </NavLink>
                                </NavItem>;
                            }
                        )}
                        <NavItem className={"hoverableItem"}>
                            <NavLink
                                className={classnames({active: navigation.tab.active.code === "ORDER_FORM_TAB"})}
                                onClick={() => toggleProductTab("ORDER_FORM_TAB")}>
                                <Button color={"link"}
                                        className={"mr-2"}>
                                    ORDER FORM
                                </Button>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent
                        activeTab={navigation.tab.active.code}>
                        {navigation.tab.list.map((p, pgIndex) => {
                                return <TabPane tabId={p.code} key={pgIndex}>
                                    {
                                        products.some(x => x.parentProductID === p.id)
                                            ? <SalesOrderProductBuilderNavigationSubTab
                                                user={user}
                                                order={order}
                                                products={products}
                                                navigation={navigation}
                                                discountByProductCode={discountByProductCode}
                                                packagingAndHandling={packagingAndHandling}
                                                handleOrderChange={handleOrderChange}
                                                toggleProductTab={toggleProductTab}
                                                productMarkupByProductCode={productMarkupByProductCode}
                                                hasBulkOrderData={hasBulkOrderData}
                                            />
                                            : <SalesOrderProductBuilderNavigationProduct
                                                user={user}
                                                order={order}
                                                product={p}
                                                products={products}
                                                discountByProductCode={discountByProductCode}
                                                packagingAndHandling={packagingAndHandling}
                                                handleOrderChange={handleOrderChange}
                                                toggleProductTab={toggleProductTab}
                                                productMarkupByProductCode={productMarkupByProductCode}
                                                hasBulkOrderData={hasBulkOrderData}
                                            />
                                    }
                                </TabPane>
                            }
                        )}
                        <TabPane tabId={"ORDER_FORM_TAB"}>
                            <OrderForm order={order}
                                       isExtUser={user.isExternalUser}
                                       handleOrderChange={handleOrderChange}

                            />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </div>
        )
    }
}